<template>
  <div class="m-0 px-3 pt-4 pb-0 pr-2">
    <back-button/>
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="m-0">View Info</h1>
    </div>
    <div class="mt-4 row mx-0" v-loading="loaders.program">
      <div class="col-lg-6 p-0">
        <card class="no-border-card" body-classes="px-0">
          <div slot="header" class="">
            <div class="d-flex justify-content-between align-items-center">
              <h2 class="mb-0">{{ response.program.first_name }}</h2>
              <router-link :to="{name: 'admin.baritastic_program.edit', params: {id: programId}}">
                <base-button type="dark-blue" outline size="xl">
                  Edit
                </base-button>
              </router-link>
            </div>
            <div class="d-inline-block mt-3">
              <p class="show-status-class px-3 py-1 mb-0"
                 :class="getStatusClass(response.program.status)">
                {{ getStatus(response.program.status) }}
              </p>
            </div>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">Program Code</div>
            <div class="col-6">{{ response.program.programmer_code }}</div>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">Username</div>
            <div class="col-6">{{ response.program.username }}</div>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">Owner Name</div>
            <div class="col-6">{{ response.program.owner_name }}</div>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">Password</div>
            <div class="col-6">{{ response.program.password1 }}</div>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">Created On</div>
            <div class="col-6">{{ response.program.created | getFormattedDate }}</div>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">Account Number</div>
            <div class="col-6">{{ response.program.account_number ? response.program.account_number: 'N/A' }}</div>
          </div>
          <div class="row mb-3 pl-4">
            <div class="col-6 color-heading-light">Modified On</div>
            <div class="col-6">{{ response.program.modified | getFormattedDate }}</div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton";
import moment from "moment";

export default {
  name: "ShowProgram",
  components: {
    BackButton
  },
  data() {
    return {
      loaders: {
        program: false
      },
      response: {
        program: {...this.$store.getters['BaritasticProgramModule/getBaritasticProgram']}
      },
      programId: this.$route.params.id,
    }
  },
  filters: {
    /**
     * Reformat Date
     * @param date
     * @returns {string}
     */
    getFormattedDate: function (date) {
      return moment.utc(date).local().format('DD-MMM-YYYY')
    }
  },
  mounted() {
    this.getBaritasticProgram()
  },
  methods: {
    /**
     * Get trophy card status
     * @param status
     * @returns {string}
     */
    getStatus(status) {
      return status === 0 ? 'Inactive' : 'Active'
    },

    /**
     * Get status class
     * @param status
     * @returns {string}
     */
    getStatusClass(status) {
      return status === 0 ? 'inactive-status-background' : 'active-status-background'
    },

    /**
     * Get Baritastic Program
     */
    getBaritasticProgram() {
      let vm = this
      vm.loaders.program = true
      const payload = {
        id: vm.programId,
      }
      vm.$store.dispatch('BaritasticProgramModule/_getRequestedBaritasticProgram', payload)
        .then(response => {
          vm.response.program = response.data.data
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Baritastic Program',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.program = false
        })
    },
  }
}
</script>

<style scoped>

</style>
